import { pxToRem } from '@archipro-design/aria';
import type { RenderAnchorTag } from '@archipro-website/top-navigation';
import type { ProfessionalTileViewModel } from '../type';
/*
This function has been duplicated at /projects/archipro-website/themes/archipro2/javascript/react/layout/TopNavigation/components/TopNavigationAria/getFeaturedTile.tsx
and must be kept in sync
*/
export const getProfessionalTile = (
    item: ProfessionalTileViewModel,
    renderAnchorTag: RenderAnchorTag
) => {
    return {
        image: [item.image],
        logo: {
            image: item.logo,
            variables: {
                avatarImageBackgroundColor: item.logoBackgroundColor,
            },
        },
        size: 'medium',
        title: item.title,
        category: item.category,
        memberSince: item.memberSince,
        variables: {
            width: pxToRem(462),
        },
        variant: '04',
        services: item.services,
        ...renderAnchorTag(item.link),
    };
};
